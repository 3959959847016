import useLanguage from './useLanguage';
import WebsiteAPI from 'api/WebsiteAPI';

/**
 *
 * @param {Object=} arg1
 * @param {Parameters<typeof WebsiteAPI.useContentQuery>[1]=} arg2
 * @returns
 */
function useContentQuery(arg1, arg2) {
	const { language } = useLanguage();
	return WebsiteAPI.useContentQuery({ lang: language, ...arg1 }, arg2);
}

export default useContentQuery;
