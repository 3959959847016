import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ContainerSection from 'common/ContainerSection';
import Preloader from 'preloader/Preloader';
import Card from 'common/Card';
import useContentQuery from 'hooks/useContentQuery';
import BlogAPI from 'api/BlogAPI';
import { extractPTagsFromHTML, extractTextFromPTags } from 'common/utils';
import { languageFAQContentEnum } from 'common/constants';

export default function Blog() {
	const content = useContentQuery();

	const { data, isLoading } = BlogAPI.useBlogQuery();

	const { global } = useSelector((global) => global);

	const blogData = data?.data?.data?.filter(
		(value) => value.content === languageFAQContentEnum[global.language]
	);

	const mostRecentPost = blogData?.[0];
	const recentPosts = blogData?.slice(1, 4);

	if (isLoading) {
		return <Preloader />;
	}

	return (
		<ContainerSection whiteNavbar>
			<header
				style={{
					background: 'linear-gradient(180deg, #3973CA 0%, #081F60 100%)',
					height: '60vh',
				}}
				className='  w-full  flex justify-start  items-center'
			>
				<div className='  w-full mx-auto  container   max-w-md  '>
					<div className=' text-center'>
						<Typography variant='h1' color='white' className='font-bold'>
							{content?.data?.data?.fainzy_blog_header}
						</Typography>
					</div>
				</div>
			</header>
			{mostRecentPost ? (
				<section className='w-full relative flex items-center'>
					<div
						className='container mx-auto  px-4  mt-10 mb-20 flex-wrap-custom flex justify-between gap-20 items-center'
						style={{ minHeight: '60vh' }}
					>
						<div>
							<img
								className='blog-img'
								alt='blog img'
								src={mostRecentPost.image}
							/>
						</div>
						<div className='max-w-md '>
							<Typography style={{ maxWidth: '19rem' }} variant='h2'>
								{mostRecentPost.title}
							</Typography>
							<Typography variant='body1' className='mt-5 text-gray-400'>
								<div
									dangerouslySetInnerHTML={{
										__html:
											extractTextFromPTags(
												extractPTagsFromHTML(mostRecentPost.body)
											)
												.substring(0, 500)
												.trim() + '...',
									}}
								></div>
							</Typography>
							<Link to={mostRecentPost.slug}>
								<Button
									variant='contained'
									className='font-semibold mt-4'
									size='medium'
									disableElevation
								>
									{content?.data?.data?.fainzy_blog_read_more_btn}
								</Button>
							</Link>
						</div>
					</div>
				</section>
			) : (
				<div style={{ minHeight: '30vh' }}></div>
			)}
			{recentPosts?.length > 0 ? (
				<section>
					<div
						className='container mx-auto  px-4'
						style={{ minHeight: '30vh' }}
					>
						<div className='max-w-md '>
							<Typography variant='h2'>
								{content?.data?.data?.fainzy_blog_recent_posts}
							</Typography>
						</div>
						<div
							className='flex md:gap-1 lg:gap-40 justify-start flex-wrap mt-10'
							style={{ minHeight: 'auto' }}
						>
							{recentPosts?.map((item, i) => (
								<Card
									key={i}
									btnText={content?.data?.data?.fainzy_blog_read_more_btn}
									title={item.title}
									body={
										extractTextFromPTags(extractPTagsFromHTML(item.body))
											.substring(0, 150)
											.trim() + '...'
									}
									slug={item.slug}
								/>
							))}
						</div>
					</div>
					<div className='flex justify-end mr-10 mb-10 mx-auto'>
						<Link to='/blog/posts'>
							<Button
								variant='outlined'
								className='font-semibold mt-4'
								size='large'
								disableElevation
							>
								{content?.data?.data?.fainzy_blog_all_btn}
							</Button>
						</Link>
					</div>
				</section>
			) : (
				<></>
			)}
		</ContainerSection>
	);
}
