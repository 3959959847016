import { Typography } from '@mui/material';
import ContainerSection from 'common/ContainerSection';
import { returnDate } from 'common/utils';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	FacebookIcon,
	LinkedinIcon,
	TwitterIcon,
	TelegramIcon,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	TelegramShareButton,
} from 'react-share';
import BlogAPI from 'api/BlogAPI';
import Preloader from 'preloader/Preloader';
import { languageFAQContentEnum } from 'common/constants';
import Home from 'pages/home/Home';
import './blog.css';

export default function BlogDetail() {
	const { data, isLoading } = BlogAPI.useBlogQuery();
	const { slug } = useParams();
	const { global } = useSelector((global) => global);

	const blogData = data?.data?.data.filter(
		(value) => value.content === languageFAQContentEnum[global.language]
	);

	const blogPost = blogData?.find(
		(post) =>
			post.slug === slug.toString() &&
			post.content === languageFAQContentEnum[global.language]
	);

	if (isLoading) {
		return <Preloader />;
	}

	if (!blogPost) {
		return <Home />;
	}

	return (
		<ContainerSection>
			<header className='  w-full mx-auto mt-40  container '>
				<div className=' text-center'>
					<Typography variant='h1' color='black' className='font-bold'>
						{blogPost?.title}
					</Typography>
				</div>
			</header>
			<section className='main'>
				<div className='date-created mb-10'>
					{returnDate(blogPost?.created)}
				</div>
				<div className='image'>
					<img alt='blog img' src={blogPost?.image} />
				</div>

				<div
					className='body-2'
					dangerouslySetInnerHTML={{ __html: blogPost?.body }}
				></div>
			</section>
			<section className='share-post'>
				<FacebookShareButton
					title={blogPost?.title}
					url={`https//fainzy.tech/blog/${blogPost?.slug}`}
					children={<FacebookIcon size={40} round={true} />}
				/>
				<TwitterShareButton
					title={blogPost?.title}
					url={`https//fainzy.tech/blog/${blogPost?.slug}`}
					children={<TwitterIcon size={40} round={true} />}
				/>
				<TelegramShareButton
					title={blogPost?.title}
					url={`https//fainzy.tech/blog/${blogPost?.slug}`}
					children={<TelegramIcon size={40} round={true} />}
				/>
				<LinkedinShareButton
					title={blogPost?.title}
					url={`https//fainzy.tech/blog/${blogPost?.slug}`}
					children={<LinkedinIcon size={40} round={true} />}
				/>
			</section>
		</ContainerSection>
	);
}
