import { Typography } from '@mui/material';
import ContainerSection from 'common/ContainerSection';
import Card from '../../common/Card';
import useContentQuery from 'hooks/useContentQuery';

const Career = () => {
	const { data } = useContentQuery();
  
	return (
		<ContainerSection whiteNavbar>
			<header
				style={{
					background: 'linear-gradient(180deg, #3973CA 0%, #081F60 100%)',
					height: '60vh',
				}}
				className='  w-full  flex justify-start  items-center'
			>
				<div className='  w-full mx-auto  container   max-w-md  '>
					<div className=' text-center'>
						<Typography variant='h1' color='white' className='font-bold'>
							{data?.data.careers_header}
						</Typography>
					</div>
				</div>
			</header>

			<section className='w-full relative flex items-center'>
				<div
					className='container mx-auto  px-4    flex justify-end flex-wrap items-center'
					style={{ minHeight: '60vh' }}
				>
					<div className='max-w-md '>
						<Typography style={{ maxWidth: '19rem' }} variant='h2'>
							{data?.data.careers_subheader}
						</Typography>
						<Typography variant='body1' className='mt-5 text-gray-400'>
							{data?.data.careers_subheader_text}
						</Typography>
					</div>
				</div>
			</section>
			{data?.data.careers.length > 0 ? (
				<section>
					<div
						className='container mx-auto  px-4'
						style={{ minHeight: '30vh' }}
					>
						<div className='max-w-md '>
							<Typography variant='h2'>Available roles</Typography>
						</div>
						<div
							className='flex justify-between flex-wrap mt-10'
							style={{ minHeight: '60vh' }}
						>
							{data?.data.careers.map((item, i) => (
								<Card
									key={i}
									btnText='Apply'
									title={item.job_title}
									description={item.job_description}
								/>
							))}
						</div>
					</div>
				</section>
			) : (
				<></>
			)}
		</ContainerSection>
	);
};

export default Career;
