export function formatCurrencyToNumber(string) {
	return string.toString().replace(/,/g, '');
}

export function formatNumber(n) {
	return n
		.toString()
		.replace(/\D/g, '')
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumberToCurrency(number = '', decimalPlace = false) {
	let value = number.toString();
	const decimalPosition = value.indexOf('.');
	if (decimalPosition >= 0) {
		let leftSide = formatNumber(value.substring(0, decimalPosition));
		let rightSide = formatNumber(value.substring(decimalPosition));
		if (decimalPlace) {
			rightSide += '00';
		}

		rightSide = rightSide.substring(0, 2);
		value = leftSide + '.' + rightSide;
	} else {
		value = formatNumber(value);
		if (decimalPlace) {
			value += '.00';
		}
	}
	return value;
}

export function isObject(item) {
	return item && typeof item === 'object' && !Array.isArray(item);
}

function is(x, y) {
	if (x === y) {
		return x !== 0 || y !== 0 || 1 / x === 1 / y;
	} else {
		/* eslint-disable no-self-compare */
		return x !== x && y !== y;
	}
}

/**
 * @template C
 * @param {C} callback
 * @param {number} wait
 * @returns {C & {flush: Function, cancel: Function}}
 */
export function debounce(callback, wait = 0) {
	let debounceTimer;
	let triggerArgs;
	let triggerThis;

	function trigger(...arg) {
		triggerArgs = arg;
		triggerThis = this;
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => {
			callback.apply(triggerThis, triggerArgs);
		}, wait);
	}

	trigger.cancel = () => clearTimeout(debounceTimer);
	trigger.flush = () => {
		clearTimeout(debounceTimer);
		callback.apply(triggerThis, triggerArgs);
	};

	return trigger;
}

export function throttle(callback, wait = 0) {
	let throttleTimer;
	let triggerArgs;
	let triggerThis;
	function trigger() {
		triggerArgs = arguments;
		triggerThis = this;
		if (throttleTimer) return;
		throttleTimer = true;
		setTimeout(() => {
			callback.apply(triggerThis, triggerArgs);
			throttleTimer = false;
		}, wait);
	}

	trigger.cancel = () => clearTimeout(throttleTimer);
	trigger.flush = () => {
		clearTimeout(throttleTimer);
		callback.apply(triggerThis, triggerArgs);
	};

	return trigger;
}

export function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export function deepMerge(target, source) {
	if (Array.isArray(target) && Array.isArray(source)) {
		const newTarget = [...target];
		for (const key in source) {
			if (typeof source[key] === 'object') {
				newTarget[key] = deepMerge(newTarget[key] || {}, source[key]);
			} else {
				newTarget[key] = source[key] || newTarget[key];
			}
		}
	} else if (isObject(target) && isObject(source)) {
		const newTarget = { ...target };
		for (const key in source) {
			if (isObject(source[key])) {
				newTarget[key] = deepMerge(newTarget[key] || {}, source[key]);
			} else {
				newTarget[key] = source[key] || newTarget[key];
			}
		}
		return newTarget;
	}
	return undefined;
}

export function shallowEqual(objA, objB) {
	if (is(objA, objB)) return true;

	if (
		typeof objA !== 'object' ||
		objA === null ||
		typeof objB !== 'object' ||
		objB === null
	) {
		return false;
	}

	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) return false;

	for (let i = 0; i < keysA.length; i++) {
		if (
			!Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
			!is(objA[keysA[i]], objB[keysA[i]])
		) {
			return false;
		}
	}

	return true;
}

/**
 * @template {{}} T
 * @param {T} obj
 * @param {string} desc
 */
export function objectAccessor(obj, desc) {
	const arr = desc ? desc.split('.') : [];
	let result = obj;
	while (arr.length && (result = result?.[arr.shift()]));
	return result;
}

/**
 * @template {{}} T
 * @param {T} values
 * @param {{allowEmptyArray: boolean}} options
 * @returns
 */
export function removeEmptyProperties(values, options = {}) {
	const { allowEmptyArray } = options;
	const newTarget = Array.isArray(values) ? [] : isObject(values) ? {} : values;

	if (typeof newTarget === 'object') {
		for (const key in values) {
			const value = values[key];
			if (
				(Array.isArray(value) && (allowEmptyArray || value.length)) ||
				(isObject(value) && Object.entries(value).length !== 0)
			) {
				newTarget[key] =
					value instanceof File ? value : removeEmptyProperties(value);
			} else if (
				value !== undefined &&
				value !== null &&
				value !== '' &&
				!Array.isArray(value) &&
				!isObject(value)
			) {
				newTarget[key] = removeEmptyProperties(value);
			}
		}
	}
	return newTarget;
}

export function objectToFormData(data) {
	const fd = new FormData();
	for (const key in data) {
		if (Array.isArray(data[key])) {
			for (const arrData of data[key]) {
				fd.append(key, arrData);
			}
		} else {
			fd.set(key, data[key]);
		}
	}
	return fd;
}

export function extractPTagsFromHTML(htmlString) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');
	const paragraphs = doc.getElementsByTagName('p');
	let result = '';

	for (let i = 0; i < paragraphs.length; i++) {
		result += paragraphs[i].outerHTML;
	}

	return result;
}

export function returnDate(date) {
	const monthsMap = {
		0: 'Jan',
		1: 'Feb',
		2: 'Mar',
		3: 'Apr',
		4: 'May',
		5: 'Jun',
		6: 'Jul',
		7: 'Aug',
		8: 'Sept',
		9: 'Oct',
		10: 'Nov',
		11: 'Dec',
	};
	const dateStr = new Date(date);
	const day = dateStr.getDate();
	const month = monthsMap[dateStr.getMonth()];
	const year = dateStr.getFullYear();
	return `${month} ${day}, ${year}`;
}

export function extractTextFromPTags(html) {
	// Create a temporary element to parse the HTML string
	const tempElement = document.createElement('div');
	tempElement.innerHTML = html;

	// Select all the <p> elements
	const paragraphs = tempElement.querySelectorAll('p');

	// Extract the text content of each <p> element
	const textArray = Array.from(paragraphs).map((p) => p.textContent.trim());

	// Join the text content into a single string
	const text = textArray.join(' ');

	return text;
}
