const YoutubeFrame = ({ className, src, width, height }) => (
	<iframe
		className={className}
		src={src}
		width={width}
		height={height}
		loading='lazy'
		title='YouTube video player'
		allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
		allowFullScreen={true}
	></iframe>
);

export default YoutubeFrame;
