import LoadingIndicator from 'common/Loading';
import React from 'react';

export default function Preloader() {
	return (
		<div className='flex justify-center items-center p-8'>
			<LoadingIndicator />
		</div>
	);
}
