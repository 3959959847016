import { createSlice } from '@reduxjs/toolkit';
import { logoutAction } from './StoreActionConfig';

export const globalInitialState = {
	language: 'en',
};

const slice = createSlice({
	name: 'global',
	initialState: globalInitialState,
	reducers: {
		setLanguageAction: (state, { payload }) => {
			state.language = payload;
		},
	},
	extraReducers: (builder) =>
		builder.addCase(logoutAction, () => ({ ...globalInitialState })),
});

export const { setLanguageAction } = slice.actions;

export default slice;

export function getGlobalSliceStorageState({ language }) {
	return { language };
}
