import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
  Typography,
} from "@mui/material";
import ContainerSection from "common/ContainerSection";
import React, {useState} from "react";
import {ReactComponent as Circle} from "assets/svgs/circle-eclipse.svg";
import {ReactComponent as ArrowDown} from "assets/svgs/arrow-down.svg";
import Success from "assets/svgs/ok.svg";
import Failure from "assets/pngs/failure-occured.png";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Link} from "react-router-dom";
import SwiperCore, {Autoplay, Scrollbar, A11y, Navigation, HashNavigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import * as yup from "yup";
import {useFormik} from "formik";

import FaqApi from "api/FaqApi";
import {languageFAQContentEnum} from "common/constants";
import {getTextFieldFormikProps} from "common/FormikUtils";
import YoutubeFrame from "common/YoutubeFrame";
import useContentQuery from "hooks/useContentQuery";
import FaqContactApi from "api/FaqContactApi";
import {useSelector} from "react-redux";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./home.css";

SwiperCore.use([Autoplay, Scrollbar, A11y, Navigation, HashNavigation]);

export default function Home() {
  const [formSuccess, setFormSuccess] = useState(false);
  const [formFailure, setFormFailure] = useState(false);

  const {data} = useContentQuery();
  const faqs = FaqApi.useFaqQuery();
  const {global} = useSelector((global) => global);

  const faqData = faqs?.data?.data.filter(
    (value) => value.content === languageFAQContentEnum[global.language]
  );

  const videoData = data?.data.home_video_carousel;

  const settings = {
    slidesPerView: 1,
    autoHeight: true,
    autoplay: {
      reverseDirection: false,
    },
  };

  const youtubeSettings = {
    slidesPerView: 1,
    navigation: true,
  };

  const impacts = [
    {
      name: data?.data.we_will_keep_making_impact_stat_1,
      stats: "240+",
      icon: "https://res.cloudinary.com/fainzy-technologies/image/upload/v1649337443/Vector_g8dvlz.svg",
    },
    {
      name: data?.data.we_will_keep_making_impact_stat_2,
      stats: "122+",
      icon: "https://res.cloudinary.com/fainzy-technologies/image/upload/v1649337443/ri_hotel-fill_yranxq.svg",
    },
    {
      name: data?.data.we_will_keep_making_impact_stat_3,
      stats: "14+",
      icon: "https://res.cloudinary.com/fainzy-technologies/image/upload/v1649337443/Vector_g8dvlz.svg",
    },
    {
      name: data?.data.we_will_keep_making_impact_stat_4,
      stats: "240+",
      icon: "https://res.cloudinary.com/fainzy-technologies/image/upload/v1649337443/ri_hotel-fill_yranxq.svg",
    },
    {
      name: data?.data.we_will_keep_making_impact_stat_5,
      stats: "240+",
      icon: "https://res.cloudinary.com/fainzy-technologies/image/upload/v1649337443/Vector_g8dvlz.svg",
    },
    {
      name: data?.data.we_will_keep_making_impact_stat_6,
      stats: "240+",
      icon: "https://res.cloudinary.com/fainzy-technologies/image/upload/v1649337443/Vector_g8dvlz.svg",
    },
  ];
  // const [updateSite, updateSiteResult] = WebsiteAPI.useUpdateSiteMutation();
  const [postMessage] = FaqContactApi.useFaqContactMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      body: "",
    },
    // validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: yup.object({
      name: yup.string().label("Name").required(),
      email: yup.string().email().label("Email Address").trim().required(),
      body: yup.string().trim().label("Body").required(),
      subject: yup.string().trim().label("Subject").required(),
    }),
    onSubmit: async (values) => {
      try {
        const {data} = await postMessage(values);
        if (data?.status === "success") {
          setFormSuccess(true);
        }
      } catch (error) {
        setFormFailure(true);
      }
    },
  });

  return (
    <ContainerSection whiteNavbar>
      <header
        style={{
          background: "linear-gradient(180deg, #3973CA 0%, #081F60 100%)",
        }}
        className=" w-full min-h-screen flex justify-center items-end"
      >
        <Circle className="hidden md:block absolute left-0" />
        <div className="container flex gap-20 justify-center items-end py-[100px] md:flex-row flex-col z-10 px-5 md:px-2">
          <div className="flex gap-2 md:flex-row flex-col">
            <div className="max-w-xl">
              <Typography variant="h1" color="white" className="font-bold mb-10">
                {data?.data.home_header}
              </Typography>
              <Typography variant="body-1" className="text-gray-100 mt-10  block">
                {data?.data.home_header_text}
              </Typography>
              <a href="#products">
                <Button
                  className="capitalize font-semibold mt-16"
                  disableElevation
                  variant="contained"
                  size="large"
                  style={{padding: "11px 22px"}}
                >
                  {data?.data.home_header_button}
                </Button>
              </a>

              <div className="mt-24 cursor-pointer flex items-center" color="white">
                <ArrowDown />
                <Typography
                  variant="caption"
                  color="white"
                  style={{
                    writingMode: "vertical-rl",
                    textOrientation: "mixed",
                  }}
                >
                  {data?.data.home_scroll_down_arrow}
                </Typography>
              </div>
            </div>
          </div>
          <Swiper {...settings} className="w-full flex justify-center  md:w-[900px] ">
            <SwiperSlide>
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1655995148/C6_05_May_2022_004.305_s1nvwv.png"
                className="pr"
                alt="Robot"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1655838260/C2_Zi_boT_003.328_arby0v.png"
                className=""
                alt="Robot"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1655997323/C5_foodiy_Bot_006.112_cy7q8a.png"
                className=""
                alt="Robot"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </header>

      {/* start about us */}
      <section className="w-full min-h-screen relative flex items-center">
        <div className="w-full px-5 md:px-10 my-24">
          <div className="flex flex items-center flex-col justify-center ">
            <div className=" max-w-sm text-center">
              <Typography variant="h2" className="text-light-blue">
                {data?.data.about_us_header}
              </Typography>
              <Typography variant="body1" className="mt-5 text-gray-400">
                {data?.data.home_about_us_text}
              </Typography>
            </div>
            <Link to="/about">
              <Button
                className="mt-8 font-semibold max-w-xs"
                variant="contained"
                size="small"
                style={{padding: "10px 20px"}}
              >
                {data?.data.home_about_us_btn}
              </Button>
            </Link>
          </div>

          <div className="mt-[200px]">
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2 items-center">
              <div className="flex justify-center order-2 md:order-1 mt-5 md:mt-0">
                <div className="max-w-md">
                  <Typography variant="h2">{data?.data.home_about_us_subheader}</Typography>
                  <Typography variant="body1" className="mt-5 text-gray-400">
                    {data?.data.home_about_us_subheader_description}
                  </Typography>
                </div>
              </div>

              <div className="order-1 md:order-2">
                <Swiper {...youtubeSettings}>
                  {videoData?.map((video) => (
                    <SwiperSlide>
                      <YoutubeFrame
                        className="yt-frame"
                        src={video}
                        width={900}
                        height={600}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end about us */}

      {/* start what we offer */}
      <section className="w-full min-h-screen relative flex items-center" id="products">
        <div className="w-full my-24">
          <div className="flex justify-center">
            <div className=" max-w-sm text-center">
              <Typography variant="h2" className="text-light-blue">
                {data?.data.products_header}
              </Typography>
              <Typography variant="body1" className="mt-5 text-gray-400">
                {data?.data.products_header_text}
              </Typography>
            </div>
          </div>

          <div className="mt-[100px] bg-black py-5">
            <div className="container grid grid-cols-1 md:grid-cols-2 items-center">
              <div className=" py-[100px]  px-3 md:px-0  flex justify-center order-2  md:order-1 mt-5 md:mt-0">
                <div className="max-w-md">
                  <Typography variant="h2" className="text-white">
                    {data?.data.products_food_ordering_header}
                  </Typography>
                  <Typography variant="body1" className="mt-5 text-gray-400">
                    {data?.data.products_food_ordering_description}
                  </Typography>
                  <Link to="/products/1">
                    <Button
                      className="mt-8 font-semibold max-w-xs"
                      variant="contained"
                      size="large"
                      fullWidth
                      style={{padding: "11px 22px"}}
                    >
                      {data?.data.home_learn_more_btn}
                    </Button>
                  </Link>
                </div>
              </div>

              <div className="order-1 md:order-2 flex justify-center relative h-full">
                <img
                  src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1649352130/Group_75_deg68u.png"
                  alt="Robot2"
                  className="w-full relative md:absolute top-0 max-w-[500px]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end what we offer */}

      <section className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="my-[50px] py-5">
            <div className="flex justify-center">
              <div className="container grid grid-cols-1 md:grid-cols-2 items-center">
                <div className="flex justify-center order-2 mt-5 md:mt-0">
                  <div className="max-w-md">
                    <Typography variant="h2">
                      {data?.data.products_lastmile_delivery_header}
                    </Typography>
                    <Typography variant="body1" className="mt-5 text-gray-400">
                      {data?.data.products_lastmile_delivery_description}
                    </Typography>
                    <Link to="/products/2">
                      <Button
                        className="mt-8 font-semibold max-w-xs"
                        variant="contained"
                        size="large"
                        fullWidth
                        style={{padding: "11px 22px"}}
                      >
                        {data?.data.home_learn_more_btn}
                      </Button>
                    </Link>
                  </div>
                </div>

                <div className="order-1 flex justify-center">
                  <img
                    src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1657015493/C2_Zi_boT_003_2_a1cusz.png"
                    alt="Robot2"
                    className="w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full relative flex items-center">
        <div className="w-full md:px-0 my-24">
          <div className="my-[100px] bg-black py-5">
            <div className="container py-24 px-5 md:px-0 grid grid-cols-1 md:grid-cols-2 items-center">
              <div className="flex justify-center mt-5 md:mt-0">
                <div className="max-w-md">
                  <Typography variant="h2" className="text-white">
                    {data?.data.products_hotel_delivery_header}
                  </Typography>
                  <Typography variant="body1" className="mt-5 text-gray-400">
                    {data?.data.products_hotel_delivery_description}
                  </Typography>
                  <Link to="/products/3">
                    <Button
                      className="mt-8 font-semibold max-w-xs"
                      variant="contained"
                      size="large"
                      fullWidth
                      style={{padding: "11px 22px"}}
                    >
                      {data?.data.home_learn_more_btn}
                    </Button>
                  </Link>
                </div>
              </div>

              <div className="relative h-full mt-20">
                <img
                  src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1700746683/HotelRobot_mvylhf.png"
                  alt="Robot2"
                  className="w-[1000px] max-h-[450px] md:absolute bottom-0"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="my-[50px] py-5">
            <div className="flex justify-center">
              <div className="container grid grid-cols-1 md:grid-cols-2 items-center">
                <div className="flex justify-center order-2 mt-5 md:mt-0">
                  <div className="max-w-md">
                    <Typography variant="h2">
                      {data?.data.products_restaurant_delivery_mira_x_header}
                    </Typography>
                    <Typography variant="body1" className="mt-5 text-gray-400">
                      {data?.data.products_restaurant_delivery_mira_x_description}
                    </Typography>
                    <Link to="/products/4">
                      <Button
                        className="mt-8 font-semibold max-w-xs"
                        variant="contained"
                        size="large"
                        fullWidth
                        style={{padding: "11px 22px"}}
                      >
                        {data?.data.home_learn_more_btn}
                      </Button>
                    </Link>
                  </div>
                </div>

                <div className="order-1 flex justify-center">
                  <img
                    src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1655838344/C6_05_May_2022_003.300_x2u256.png"
                    alt="Robot2"
                    className="w-full max-w-7xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full relative flex items-center bg-black">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="my-[50px]  py-5">
            <div className="flex justify-center">
              <div className="container grid grid-cols-1 md:grid-cols-2 items-center">
                <div className="flex justify-center mt-5 md:mt-0">
                  <div className="max-w-md">
                    <Typography variant="h2" className="text-white">
                      {data?.data.products_demand_forecast_header}
                    </Typography>
                    <Typography variant="body1" className="mt-5 text-gray-400">
                      {data?.data.products_demand_forecast_description}
                    </Typography>
                    <Link to="/products/5">
                      <Button
                        className="mt-8 font-semibold max-w-xs"
                        variant="contained"
                        size="large"
                        fullWidth
                        style={{padding: "11px 22px"}}
                      >
                        {data?.data.home_learn_more_btn}
                      </Button>
                    </Link>
                  </div>
                </div>

                <div>
                  <img
                    src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1649351712/image_18_ttymbx.png"
                    alt="Robot2"
                    className="w-full max-w-[550px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-ful pt-6 flex flex items-center flex-col justify-center my-20">
        <div className="w-full px-5 md:px-0 ">
          <div className="flex flex items-center flex-col justify-center mt-10 ">
            <div className=" max-w-sm text-center">
              <Typography variant="h2" className="my-4">
                {data?.data.other_services_header}
              </Typography>
              {/* <Typography variant="body1" className="mb-10 text-gray-400">
                Watch the video below for a clear demonstration of how the
                product works.
              </Typography> */}
            </div>
          </div>
          <div className=" flex justify-center flex-wrap ">
            {data?.data.otherservices_set.map((item, i) => (
              <Card sx={{maxWidth: 345}} className="mx-4">
                <CardMedia component="img" height="140" image={item.image} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link to="/contact-us">
                    <Button size="large">{data?.data.contact_us_header}</Button>
                  </Link>
                </CardActions>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="flex justify-center">
            <div className=" max-w-sm text-center">
              <Typography variant="h2" className="text-light-blue">
                {data?.data.we_will_keep_making_impact_header}
              </Typography>
            </div>
          </div>
          <div className="my-[50px] py-5">
            <div className="flex justify-center">
              <div className="container max-w-2xl">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-center">
                  {impacts.map((impact, i) => (
                    <div className="flex flex-col gap-2 px-6 items-center justify-center shadow-md rounded-3xl py-10 h-full ">
                      <img
                        src={impact.icon}
                        alt={impact.stats}
                        className="w-full max-w-[50px]"
                      />
                      <Typography variant="h3">{}</Typography>
                      <Typography variant="body2 " className="text-gray-500 text-center">
                        {impact.name}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faqs" className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0 my-20">
          <div className="my-[50px] py-5">
            <div className="flex justify-center">
              <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
                  <div>
                    <div className="flex">
                      <div className="max-w-2xl">
                        <Typography variant="h2" className="text-light-blue">
                          {data?.data.home_faq_header}
                        </Typography>

                        <div className="mt-10 ">
                          {faqData?.map((faq, i) => (
                            <div key={i} className="shadow-lg rounded-lg mt-5">
                              <Accordion elevation={0}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>{faq.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>{faq.answer}</Typography>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {formSuccess ? (
                    <div className="container max-w-2xl my-auto text-center ">
                      <img
                        className="mx-auto"
                        src={Success}
                        alt="Completed"
                        width={80}
                        height={80}
                      />
                      <p className="text-center text-gray-800">
                        Thank you for reaching out. We will get back to you soon.
                      </p>
                    </div>
                  ) : formFailure ? (
                    <div className="container max-w-2xl my-auto text-center ">
                      <img
                        className="mx-auto"
                        src={Failure}
                        alt="Failed"
                        width={80}
                        height={80}
                      />
                      <p className="text-center text-gray-800">
                        Request could not be processed. Please try again later.
                      </p>
                    </div>
                  ) : (
                    <div className="max-w-2xl">
                      <Typography variant="h3" className=" mt-10 ">
                        {data?.data.home_form_subheader}
                      </Typography>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="grid grid-cols-1 mt-5 gap-2">
                          <TextField
                            label={data?.data.home_contact_us_form_fields_placeholders.name}
                            fullWidth
                            {...getTextFieldFormikProps(formik, "name")}
                          />
                          <TextField
                            fullWidth
                            label={data?.data.home_contact_us_form_fields_placeholders.email}
                            {...getTextFieldFormikProps(formik, "email")}
                            type="email"
                          />
                          <TextField
                            fullWidth
                            {...getTextFieldFormikProps(formik, "subject")}
                            label={data?.data.home_contact_us_form_fields_placeholders.subject}
                          />
                          <TextField
                            fullWidth
                            label={data?.data.home_contact_us_form_fields_placeholders.message}
                            multiline
                            rows={3}
                            {...getTextFieldFormikProps(formik, "body")}
                          />

                          <div>
                            <Button
                              variant="contained"
                              size="large"
                              type="submit"
                              loading="Please wait..."
                            >
                              {data?.data.form_submit_now_btn}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full mt-20 relative flex items-center">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="my-[100px] py-5">
            <div className="flex justify-center">
              <div
                style={{
                  background: " linear-gradient(180deg, #3973CA 0%, #081F60 100%)",
                }}
                className="container py-24 px-10 rounded-xl grid grid-cols-1 md:grid-cols-2 items-center"
              >
                <div className="flex justify-center order-2 mt-5 md:mt-0">
                  <div className="max-w-md">
                    <Typography variant="h2" className="text-white">
                      {data?.data.home_cta_subheader_below_faq}
                    </Typography>
                    <Link to="/contact-us">
                      <Button
                        className="m-2 font-semibold  text-gray-800 bg-white"
                        variant="contained"
                        //   color="white"
                        size="large"
                        style={{padding: "11px 22px"}}
                      >
                        {data?.data.home_contact_us_btn}
                      </Button>
                    </Link>
                    <Link to="contact-us">
                      <Button
                        className="m-2 font-semibold    "
                        variant="contained"
                        //   color="white"
                        size="large"
                        style={{padding: "11px 22px", color: "white"}}
                      >
                        {data?.data.home_get_started_btn}
                      </Button>
                    </Link>
                  </div>
                </div>

                <div className="order-1 relative h-full">
                  <img
                    src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1657867043/Group_78_ncnro3.png"
                    alt="Robot2"
                    className="w-full md:absolute bottom-0 max-w-[500px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0">
          <div className="my-[50px] py-5">
            <div className="flex justify-center">
              <div className="container">
                <Typography className="text-center" variant="h3">
                  {data?.data.brands_that_trust_us}
                </Typography>

                <div className="flex gap-4 overflow-x-scroll mt-10">
                  {partners.map((partner, i) => (
                    <img src={partner.logo} alt={partner.name} className="w-full max-w-sm" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContainerSection>
  );
}

const partners = [
  {
    name: "Nagoya University",
    logo: "https://res.cloudinary.com/fainzy-technologies/image/upload/v1655987686/nagoya-u_ydwlpd.png",
  },
];
