import {Button, Rating, Typography} from "@mui/material";
import ContainerSection from "common/ContainerSection";
import React from "react";
import {useParams} from "react-router-dom";
import useContentQuery from "hooks/useContentQuery";

export default function Products() {
  const {data} = useContentQuery();
  const {id} = useParams();
  const thisProduct = data?.data?.product_set?.find(
    (prod) => parseInt(prod.product_number) === parseInt(id)
  );

  const getLink = (id) => {
    switch (id) {
      case "1":
        return "https://dashboard.fainzy.ai/register/4";
      case "2":
        return "https://dashboard.fainzy.ai/register/1";
      case "3":
        return "https://dashboard.fainzy.ai/register/2";
      case "4":
        return "https://dashboard.fainzy.ai/register/3";
      default:
        break;
    }
  };

  return (
    <ContainerSection>
      <header className=" w-full relative flex justify-center items-center">
        <div className="container grid grid-cols-1 md:grid-cols-2 gap-5 items-center py-[100px] md:flex-row flex-col z-10 px-5 md:px-2">
          <div>
            <div className="max-h-lg">
              <img alt={thisProduct?.name} src={thisProduct?.image} />
            </div>
          </div>

          <div className="flex justify-center">
            <div className="max-w-md">
              <Typography variant="h3">{thisProduct?.name}</Typography>

              <div className="flex gap-2 items-end">
                <Rating
                  size="small"
                  name="half-rating-read"
                  defaultValue={thisProduct?.rating}
                  precision={0.5}
                  readOnly
                />
              </div>
              <div className="mt-5 flex gap-2 "></div>
              <a href={getLink(id)}>
                <Button
                  variant="contained"
                  size="large"
                  disableElevation
                  className="text-black bg-gray-300 mt-5 font-semibold"
                  fullWidth
                >
                  Purchase
                </Button>
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="flex flex items-center flex-col justify-center ">
            <div className=" max-w-xl text-center">
              <Typography variant="h3" className="">
                {thisProduct?.product_description_header}
              </Typography>
              <Typography variant="body1" className="mt-5 text-gray-400">
                {thisProduct?.product_description_text}
              </Typography>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full relative flex items-center ">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="flex justify-center   ">
            <div className="container  ">
              <Typography variant="h3" className="mb-5">
                {thisProduct?.technical_specification_header}
              </Typography>
              <div className="mb-4">
                <Typography variant="h6" className="py-2">
                  {thisProduct?.weight}
                </Typography>
                <Typography variant="h6" className="py-2">
                  {thisProduct?.size}
                </Typography>
                <Typography variant="h6" className="py-2">
                  {thisProduct?.speed}
                </Typography>
                <Typography variant="h3" className="my-2">
                  Features
                </Typography>
                <Typography variant="body1" className="mb-5">
                  <ul>
                    <li>{thisProduct?.features}</li>
                  </ul>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center">
              <div className=" container flex justify-center order-2 md:order-1 mt-5 md:mt-0">
                <div className="max-w-md">
                  <Typography variant="h2">{data?.data.home_about_us_subheader}</Typography>
                  <Typography variant="body1" className="mt-5 text-gray-400">
                    {data?.data.home_about_us_subheader_description}
                  </Typography>
                </div>
              </div>

              <div className="order-1 md:order-2">
                <img
                  src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1648560918/unsplash_ZPeXrWxOjRQ_jrcbcr.png"
                  alt="Robot2"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="w-ful pt-6 flex flex items-center flex-col justify-center"
        style={{backgroundColor: "#161C24"}}
      >
        <div className="w-full px-5 md:px-0 ">
          <div className="flex flex items-center flex-col justify-center mt-10 ">
            <div className=" max-w-sm text-center">
              <Typography variant="h2" className="text-white">
                Our Edge
              </Typography>
              <Typography variant="body1" className="mb-10 text-gray-400">
                At Fainzy Technologies, we are committed to
              </Typography>
            </div>
          </div>
          <div
            className=" container flex justify-evenly flex-wrap    py-8 mb-24"
            style={{margin: "auto", color: "white"}}
          >
            <div className="max-w-xs">
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1654507614/Vector_ytjffe.svg"
                alt="icon"
                className="my-4"
              />

              <Typography className="h3 font-bold">Cost Saving</Typography>
              <Typography className="body1 my-2">
                Fainzy tech strives to save money for customers, by providing tech solutions
                that minimize expenses.
              </Typography>
            </div>
            <div className="max-w-xs">
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1654507614/Vector_ytjffe.svg"
                alt="icon"
                className="my-4"
              />

              <Typography className="h3 font-bold">Time Saving</Typography>
              <Typography className="body1 my-2">
                Fainzy services help our customers save and manage their time by running their
                errands and bringing their needs to them.
              </Typography>
            </div>
            <div className="max-w-xs">
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1654507614/Vector_ytjffe.svg"
                alt="icon"
                className="my-4"
              />

              <Typography className="h3 font-bold">Green Technology</Typography>
              <Typography className="body1 my-2">
                Fainzy products and services are green technology centred, preserving the
                environment, one robot at a time
              </Typography>
            </div>
            <div className="max-w-xs">
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1654507614/Vector_ytjffe.svg"
                alt="icon"
                className="my-4"
              />

              <Typography className="h3 font-bold">Contactless delivery</Typography>
              <Typography className="body1 my-2">
                Fainzy services are pro human safety, designed to eradicate virus spread by
                eradicating contact.
              </Typography>
            </div>
          </div>
        </div>
      </section>
      <section className="my-20"></section>
    </ContainerSection>
  );
}
