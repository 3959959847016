import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ContainerSection from 'common/ContainerSection';
import Preloader from 'preloader/Preloader';
import BlogAPI from 'api/BlogAPI';
import { languageFAQContentEnum } from 'common/constants';
import {
	extractPTagsFromHTML,
	extractTextFromPTags,
	returnDate,
} from 'common/utils';
import './blog.css';
import useContentQuery from 'hooks/useContentQuery';

export default function BlogPosts() {
	const content = useContentQuery();
	const { data, isLoading } = BlogAPI.useBlogQuery();
	const { global } = useSelector((global) => global);

	const blogPosts = data?.data?.data.filter(
		(value) => value.content === languageFAQContentEnum[global.language]
	);

	if (isLoading) {
		return <Preloader />;
	}

	const posts = blogPosts?.map((post) => (
		<div className='post'>
			<div className='blog-image-section'>
				<Link to={`/blog/${post.slug}`}>
					<img className='blog-image' alt='blog img' src={post.image} />
				</Link>
			</div>
			<div className='blog-details'>
				<p className='date-published'>{returnDate(post.created)}</p>
				<Link to={`/blog/${post.slug}`}>
					{post.title.length > 40 ? (
						<h1 className='title'>{post.title.substring(0, 40) + '...'}</h1>
					) : (
						<h1 className='title'>{post.title}</h1>
					)}
				</Link>
				<p
					className='body'
					dangerouslySetInnerHTML={{
						__html:
							extractTextFromPTags(extractPTagsFromHTML(post.body))
								.substring(0, 70)
								.trim() + '...',
					}}
				></p>
			</div>
		</div>
	));
	return (
		<ContainerSection whiteNavbar>
			<header
				style={{
					background: 'linear-gradient(180deg, #3973CA 0%, #081F60 100%)',
					height: '60vh',
				}}
				className='w-full flex justify-start items-center'
			>
				<div className='w-full mx-auto container max-w-md'>
					<div className='text-center'>
						<Typography variant='h1' color='white' className='font-bold'>
							{content?.data?.data?.fainzy_blog_header}
						</Typography>
					</div>
				</div>
			</header>
			<section className='posts'>{posts}</section>
		</ContainerSection>
	);
}
