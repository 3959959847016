import { FainzyAPI } from "configs/StoreQueryConfig";

const baseURL = "/site";

const NewslettersApi = FainzyAPI.injectEndpoints({
  endpoints: (builder) => ({
    Newsletter: builder.mutation({
      query: (data) => ({
        url: `${baseURL}/newsletters/subscribe`,
        method: "post",
        body: data,
      }),
    }),
  }),
});

export default NewslettersApi;
