import {MenuItem, TextField, Typography} from "@mui/material";
import ContainerSection from "common/ContainerSection";
import React from "react";
import Card from "@mui/material/Card";

import * as yup from "yup";
import {useFormik} from "formik";
import {getTextFieldFormikProps} from "common/FormikUtils";

import Success from "assets/svgs/ok.svg";
import Failure from "assets/pngs/failure-occured.png";

import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import useContentQuery from "hooks/useContentQuery";
import FaqContactApi from "api/FaqContactApi";

const Contact = () => {
  const {data} = useContentQuery();

  const [formSuccess, setFormSuccess] = React.useState(false);
  const [formFailure, setFormFailure] = React.useState(false);

  const [postMessage, postFaqMessage] = FaqContactApi.useFaqContactMutation();

  const categoryOptions = data?.data.home_contact_us_form_fields_placeholders.category_options;

  const reasons = [
    {
      value: categoryOptions?.[0],
      label: categoryOptions?.[0],
    },
    {
      value: categoryOptions?.[1],
      label: categoryOptions?.[1],
    },
    {
      value: categoryOptions?.[2],
      label: categoryOptions?.[2],
    },
    {
      value: categoryOptions?.[3],
      label: categoryOptions?.[3],
    },
    {
      value: categoryOptions?.[4],
      label: categoryOptions?.[4],
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      body: "",
      category: "",
    },
    validationSchema: yup.object({
      name: yup.string().label("Name").required(),
      email: yup.string().email().label("Email Address").trim().required(),
      body: yup.string().trim().label("Message").required(),
      subject: yup.string().label("Subject").trim().required(),
    }),
    onSubmit: async (values) => {
      try {
        const {data} = await postMessage(values);
        if (data?.status === "success") {
          setFormSuccess(true);
        }
      } catch (error) {
        setFormFailure(true);
      }
    },
  });

  return (
    <ContainerSection whiteNavbar>
      <header
        style={{
          background: "linear-gradient(180deg, #3973CA 0%, #081F60 100%)",
          height: "60vh",
        }}
        className="  w-full  flex justify-start  items-center"
      >
        <div className="  w-full mx-auto  container   max-w-md  ">
          <div className=" text-center">
            <Typography variant="h1" color="white" className="font-bold">
              {data?.data.contact_us_header}
            </Typography>
            <Typography variant="body1" color="white" className="font-bold">
              {data?.data.contact_us_header_text}
            </Typography>
          </div>
        </div>
      </header>
      <section className="" style={{}}>
        <div className="flex  justify-center flex-wrap  -mt-20 ">
          <Card
            className=" m-5 flex flex-col justify-center"
            style={{width: "220px", height: "220px"}}
          >
            <CardContent className="text-center flex  justify-center flex-col flex items-center">
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1650978165/Vector_azwt5z.svg"
                alt="address-icon"
                className="my-4"
              />
              <Typography variant="body2">{data?.data.contact_us_address}</Typography>
            </CardContent>
          </Card>
          <Card
            className=" m-5 flex flex-col justify-center"
            style={{width: "220px", height: "220px"}}
          >
            <CardContent className="text-center flex  justify-center flex-col flex items-center">
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1650978165/Stroke-1_vq8v3f.svg"
                alt="phnonIcon"
                className="my-4"
              />
              <Typography variant="body2">{data?.data.contact_us_phone_number}</Typography>
            </CardContent>
          </Card>
          <Card
            className=" m-5 flex flex-col justify-center"
            style={{width: "220px", height: "220px"}}
          >
            <CardContent className="text-center flex  justify-center flex-col flex items-center">
              <img
                src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1650978239/Vector_soavvi.svg"
                alt="message-icon"
                className="my-4"
              />
              <Typography variant="body2">{data?.data.contact_us_email}</Typography>
            </CardContent>
          </Card>
        </div>
      </section>
      <section className="w-full flex items-center">
        <div className="w-full px-5 md:px-0 my-20">
          <div className="my-[50px] py-5">
            <div className="flex justify-center">
              <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:gap-80 md:gap-20 items-center justify-center">
                  <div className="max-w-2xl">
                    {formSuccess ? (
                      <div className="container max-w-2xl my-auto text-center ">
                        <img
                          className="mx-auto"
                          src={Success}
                          alt="Completed"
                          width={80}
                          height={80}
                        />
                        <p className="text-center text-gray-800">
                          Thank you for reaching out. We will get back to you soon.
                        </p>
                      </div>
                    ) : formFailure ? (
                      <div className="container max-w-2xl my-auto text-center ">
                        <img
                          className="mx-auto"
                          src={Failure}
                          alt="Failed"
                          width={80}
                          height={80}
                        />
                        <p className="text-center text-gray-800">
                          Request could not be processed. Please try again later.
                        </p>
                      </div>
                    ) : (
                      <>
                        <Typography variant="h3" className=" mt-10 ">
                          {data?.data.contact_us_form_subheader}
                        </Typography>
                        <form
                          onSubmit={formik.handleSubmit}
                          className="grid grid-cols-1 mt-5 gap-2"
                        >
                          <TextField
                            fullWidth
                            label={data?.data.home_contact_us_form_fields_placeholders.name}
                            {...getTextFieldFormikProps(formik, "name")}
                          />
                          <TextField
                            fullWidth
                            label={data?.data.home_contact_us_form_fields_placeholders.email}
                            {...getTextFieldFormikProps(formik, "email")}
                          />
                          <TextField
                            fullWidth
                            label={data?.data.home_contact_us_form_fields_placeholders.subject}
                            {...getTextFieldFormikProps(formik, "subject")}
                          />
                          <TextField
                            fullWidth
                            label={data?.data.home_contact_us_form_fields_placeholders.message}
                            multiline
                            rows={3}
                            {...getTextFieldFormikProps(formik, "body")}
                          />
                          <TextField
                            fullWidth
                            id="outlined-select-reason"
                            select
                            label={
                              data?.data.home_contact_us_form_fields_placeholders.category
                            }
                            {...getTextFieldFormikProps(formik, "category")}
                          >
                            {reasons.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <div>
                            <Button
                              type="submit"
                              variant="contained"
                              size="large"
                              loading={postFaqMessage.isLoading}
                            >
                              Submit Now
                            </Button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                  <div>
                    <iframe
                      title="Fainzy map"
                      width="500"
                      height="500"
                      style={{border: 20}}
                      loading="lazy"
                      allowfullscreen
                      referrerpolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_API_KEY}&q=Japan,+〒464-0814+Aichi,+Nagoya,+Chikusa+Ward,+Furōchō,+名古屋大学+インキュベーション施設`}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContainerSection>
  );
};

export default Contact;
