import { default as MaterialCard } from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Card = ({ key, btnText, title, body, slug }) => {
	const { global } = useSelector((global) => global);

	return (
		<div>
			<div className='flex justify-between flex-wrap mt-10'>
				<MaterialCard
					key={key}
					className='px-5 mt-10'
					style={{ width: '365px', height: '230px' }}
				>
					<CardContent>
						<Typography variant='h5'>{title}</Typography>
						<Typography
							variant='body2'
							className={global.language === 'en' ? 'py-2' : 'py-2 text-xs'}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: body,
								}}
							></div>
						</Typography>
						<CardActions style={{ padding: '10px 0' }}>
							{!slug ? (
								<Link to='/contact-us'>
									<Button
										variant='contained'
										className='font-semibold'
										size='medium'
										disableElevation
									>
										{btnText}
									</Button>
								</Link>
							) : (
								<Link to={slug}>
									<Button
										variant='contained'
										className='font-semibold'
										size='medium'
										disableElevation
									>
										{btnText}
									</Button>
								</Link>
							)}
						</CardActions>
					</CardContent>
				</MaterialCard>
			</div>
		</div>
	);
};

export default Card;
