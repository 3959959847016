import { FainzyAPI } from "configs/StoreQueryConfig";

const baseURL = "/site";

const WebsiteAPI = FainzyAPI.injectEndpoints({
  endpoints: (builder) => ({
    content: builder.query({
      query: (params) => ({
        url: `${baseURL}/content`,
        method: "get",
        params,
      }),
    }),
    updateSite: builder.mutation({
      query: ({ ...body }) => ({
        url: `${baseURL}/content`,
        method: "post",
        body,
      }),
    }),
    faq: builder.query({
      query: (params) => ({
        url: `${baseURL}/faqs`,
        method: "get",
        params,
      }),
    }),
  }),
});

export default WebsiteAPI;
