import {Typography, Button} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ContainerSection from "common/ContainerSection";
import React from "react";
import {Link} from "react-router-dom";
import useContentQuery from "hooks/useContentQuery";
const About = () => {
  const {data} = useContentQuery();
  const teamSet = data?.data.team_set;

  return (
    <ContainerSection whiteNavbar>
      <header
        style={{
          background: "linear-gradient(180deg, #3973CA 0%, #081F60 100%)",
          height: "60vh",
        }}
        className="w-full  relative flex justify-center items-center"
      >
        <div className="text-center">
          <Typography variant="h1" color="white" className="font-bold">
            {data?.data.about_us_header}
          </Typography>
          <Typography variant="body1" color="white" className="font-bold">
            {data?.data.about_us_header_text}
          </Typography>
        </div>
      </header>
      <section className="w-full flex justify-center ">
        <div className="container py-24 md:px-0 grid grid-cols-1 md:grid-cols-2 items-center">
          <div>
            <img
              src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1649797763/mwdmdxey3dku5zyqbek4.png"
              alt="aboutpng"
              className="px-5"
            />
          </div>
          <div className="max-w-2xl px-10">
            <Typography variant="h2">{data?.about_us_subheader}</Typography>
            <Typography variant="body1" className="mt-5 text-gray-400">
              {data?.data.about_us_subheader_text}
            </Typography>
            <Link to="/">
              <Button
                className="mt-8 font-semibold max-w-xs"
                variant="outlined"
                endIcon={<ArrowRightAltIcon />}
                size="large"
                style={{padding: "11px 22px"}}
              >
                {data?.data.about_us_see_products_btn}
              </Button>
            </Link>
          </div>
        </div>
      </section>
      {/* start team */}
      <section className="w-full   ">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="flex flex items-center flex-col justify-center ">
            <div className=" max-w-lg text-center">
              {/* <Typography variant="body1" className="mt-5 text-gray-400">
                Dream team
              </Typography> */}
              <Typography variant="h2" className="text-light-blue">
                {data?.data.about_us_team_subheader}
              </Typography>
              {/* <Typography variant="body1" className="mt-5 text-gray-400">
                Minimal will provide you support if you have any problems, our
                support team will reply within a day and we also have detailed
                documentation.
              </Typography> */}
            </div>
          </div>
        </div>
        <div className="w-full relative flex justify-center flex-wrap items-center mx-auto max-w-7xl">
          {teamSet?.map((team, i) => (
            <div className="mr-5 text-center mt-10  " style={{maxWidth: "300px"}}>
              <img
                style={{
                  width: "254px",
                  maxHeight: "233px",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
                src={team.image}
                alt={team.name}
              />
              <Typography variant="h6">{team.name}</Typography>
              <Typography variant="body1">{team.title}</Typography>
            </div>
          ))}
        </div>
      </section>
      <section className="w-full relative flex items-center">
        <div className="w-full px-5 md:px-0 my-24">
          <div className="my-[100px] py-5">
            <div className="flex justify-center">
              <div
                style={{
                  background: " linear-gradient(180deg, #3973CA 0%, #081F60 100%)",
                }}
                className="container  py-24 px-10 rounded-xl grid grid-cols-1  content-center items-center"
              >
                <div className=" w-full flex justify-center  items-center">
                  <div className="max-w-sm w-full flex justify-center  items-center flex-col ">
                    <Typography variant="h2" className="text-white text-center">
                      {data?.data.about_us_cta}
                    </Typography>
                    <Link to="/contact-us">
                      <Button
                        className="mt-8 font-semibold  items-center  text-gray-800 bg-white"
                        variant="contained"
                        //   color="white"
                        size="large"
                        style={{padding: "11px 22px"}}
                      >
                        {data?.data.about_us_get_in_touch_btn}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContainerSection>
  );
};

export default About;
