import React from "react";
import Logo from "common/Logo";
import {Button, Typography} from "@mui/material";
import {Link, NavLink} from "react-router-dom";
import useContentQuery from "hooks/useContentQuery";
import * as yup from "yup";
import {useFormik} from "formik";
import {getTextFieldFormikProps} from "common/FormikUtils";
import NewslettersApi from "api/NewslettersApi";

import Success from "assets/svgs/ok-white.svg";
import Failure from "assets/pngs/failure-occured-white.png";

export default function Footer() {
  const [newsletter] = NewslettersApi.useNewsletterMutation();

  const [formSuccess, setFormSuccess] = React.useState(false);
  const [formFailure, setFormFailure] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string().email().label("Email Address").trim().required(),
    }),
    onSubmit: async (values) => {
      try {
        const data = await newsletter(values).unwrap();
        if (data.status === "success") {
          setFormSuccess(true);
        }
      } catch (error) {
        setFormFailure(true);
      }
    },
  });
  const {data} = useContentQuery();

  const footerLink = [
    {
      title: data?.data.footer_main_pages_header,
      links: [
        {name: data?.data.footer_home_nav_item, to: "/"},
        {name: data?.data.nav_bar_item_a, to: "/about-us"},
        {name: data?.data.nav_bar_item_b, to: "/"},
        {name: data?.data.nav_bar_item_c, to: "/careers"},
        {name: data?.data.nav_bar_item_d, to: "/contact-us"},
        {name: data?.data.nav_bar_item_e, to: "/blog"},
        {name: data?.data.nav_bar_item_f, to: "https://dashboard.fainzy.ai", biz: true},
      ],
    },
    {
      title: data?.data.footer_business_account_header,
      links: [
        {name: data?.data.footer_my_subcription_nav_item, to: "/contact-us"},
        {name: data?.data.footer_profile_nav_item, to: "/"},
      ],
    },
  ];

  return (
    <div className="bg-primary-main w-full ">
      <div className="flex justify-center">
        <div className="container flex md:flex-row flex-col my-16 gap-24 px-5">
          <div>
            <div>
              <Logo variant={"logo-white"} />
            </div>

            {formSuccess ? (
              <div className="container my-10 max-w-2xl text-center ">
                <img src={Success} alt="Completed" width={40} height={40} />
                <p className="text-center text-white">
                  Thank you for subscribing to our newsletters.
                </p>
              </div>
            ) : formFailure ? (
              <div className="container my-10 max-w-2xl text-center ">
                <img src={Failure} alt="Failure" width={40} height={40} />
                <p className="text-center text-white">
                  Oops... This user is already subscribed.
                </p>
              </div>
            ) : (
              <>
                <Typography variant="h5" color="white" className="mt-12 font-bold">
                  {data?.data.footer_subscribe_to_newsletter}
                </Typography>

                <form
                  onSubmit={formik.handleSubmit}
                  className="flex items-center w-full max-w-md gap-3 mt-6"
                >
                  <input
                    className=" h-12 px-4 mb-2 text-lg text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
                    placeholder={data?.data.footer_subscription_form_placeholder}
                    label="Email"
                    {...getTextFieldFormikProps(formik, "email")}
                    type="email"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className="capitalize"
                    loading="Please wait..."
                  >
                    {data?.data.footer_subscribe_btn}
                  </Button>
                </form>

                <div className="max-w-md w-full">
                  <Typography variant="caption" className="mt-6 " color="white">
                    {data?.data.footer_subscriber_policy}
                  </Typography>
                </div>
              </>
            )}
            <div className="flex items-center mt-5">
              <Link to="/">
                <img
                  src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1657886248/IconButton_j5xrxs.png"
                  alt="social icon"
                />
              </Link>
              <Link to="/">
                <img
                  src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1657886228/IconButton_bzw5ty.png"
                  alt="social icon"
                />
              </Link>
              <Link to="/">
                <img
                  src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1657886269/IconButton_z67zph.png"
                  alt="social icon"
                />
              </Link>
              <Link to="/">
                <img
                  src="https://res.cloudinary.com/fainzy-technologies/image/upload/v1657886816/IconButton_s98srq.png"
                  alt="social icon"
                />
              </Link>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-16">
            {footerLink.map((footer, i) => (
              <div key={i}>
                <div>
                  <Typography variant="subtitle1 " className="font-semibold text-light-blue">
                    {footer.title}
                  </Typography>

                  <div className="border-light-blue border-b-2 w-full mb-6 mt-2" />
                </div>

                <ul className={`flex flex-col items-left mt-4 gap-5`}>
                  {footer.links.map((link, i) => (
                    <>
                      {!link.biz ? (
                        <li key={i}>
                          <NavLink
                            className={`text-white rounded md:bg-transparent `}
                            {...link}
                          >
                            {link.name}
                          </NavLink>
                        </li>
                      ) : (
                        <li key={i}>
                          <a
                            className={`text-white rounded md:bg-transparent `}
                            href={link.to}
                          >
                            {link.name}
                          </a>
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-8">
        <div className=" container flex items-center justify-between   gap-3 mt-6">
          <Typography variant="caption" className="mt-6 " color="white">
            {data?.data.footer_copyright_txt}
          </Typography>
          <div className="flex items-center justify-between   gap-3 ">
            <Typography variant="caption" className="mt-6 " color="white">
              {data?.data.footer_privacy_txt}
            </Typography>
            <Typography variant="caption" className="mt-6 " color="white">
              {data?.data.footer_terms_txt}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
