import './App.css';
import { useLocation, useRoutes } from 'react-router-dom';
import { RouteEnum } from 'common/constants';
import React, { Suspense } from 'react';
import Home from 'pages/home/Home';
import AppThemeProvider from 'AppThemeProvider';
import Products from 'products/Products';
import 'assets/slick/slick.css';
import 'assets/slick/slick-theme.css';
import About from 'pages/about/About';
import Blog from 'pages/blog/Blog';
import BlogDetail from 'pages/blog/BlogDetail';
import BlogPosts from 'pages/blog/BlogPosts';
import Career from 'pages/careers/Career';
import Contact from 'pages/contactUs/Contact';
import SignIn from 'pages/SignIn/SignIn';
import Register from 'pages/register/Register';
import useScrollToTop from 'hooks/useScrollToTop';
import Preloader from 'preloader/Preloader';

function App() {
	const location = useLocation();

	useScrollToTop(location);
	const ROUTES = useRoutes([
		{
			path: '*',
			element: <Home />,
		},
		{
			path: RouteEnum.PRODUCTS_DETAIL,
			element: <Products />,
		},
		{
			path: RouteEnum.ABOUT,
			element: <About />,
		},
		{
			path: RouteEnum.CAREERS,
			element: <Career />,
		},
		{
			path: RouteEnum.LOGIN,
			element: <SignIn />,
		},
		{
			path: RouteEnum.PRELOADER,
			element: <Preloader />,
		},
		{
			path: RouteEnum.REGISTER,
			element: <Register />,
		},
		{
			path: RouteEnum.HOME,
			element: <Home />,
		},
		{
			path: RouteEnum.CONTACT_US,
			element: <Contact />,
		},
		{
			path: RouteEnum.BLOG,
			element: <Blog />,
		},
		{
			path: RouteEnum.BLOG_DETAIL,
			element: <BlogDetail />,
		},
		{
			path: RouteEnum.BLOG_POSTS,
			element: <BlogPosts />,
		},
		{
			path: '*/*',
			element: <Home />,
		},
	]);

	return (
		<AppThemeProvider>
			<Suspense fallback={<Preloader />}> {ROUTES} </Suspense>{' '}
		</AppThemeProvider>
	);
}

export default App;
