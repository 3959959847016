import { FainzyAPI } from 'configs/StoreQueryConfig';

const BlogAPI = FainzyAPI.injectEndpoints({
	endpoints: (builder) => ({
		blog: builder.query({
			query: (params) => ({
				url: `/blog`,
				method: 'get',
				params,
			}),
		}),
		blogDetail: builder.query({
			query: (params) => ({
				url: `/blog/${params}`,
				method: 'get',
				params,
			}),
		}),
	}),
});

export default BlogAPI;
