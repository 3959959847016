import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguageAction } from 'configs/StoreSliceConfig';

function useLanguage() {
	const language = useSelector((state) => state.global.language);
	const dispatch = useDispatch();

	const setLanguage = useCallback(
		(payload) => {
			dispatch(setLanguageAction(payload));
		},
		[dispatch]
	);

	return { language, setLanguage };
}

export default useLanguage;
