import { FainzyAPI } from "configs/StoreQueryConfig";

const baseURL = "/site";

const FaqApi = FainzyAPI.injectEndpoints({
  endpoints: (builder) => ({
    faq: builder.query({
      query: (params) => ({
        url: `${baseURL}/faqs`,
        method: "get",
        params,
      }),
    }),
    
  }),
});

export default FaqApi;
